'use strict';

(function() {

  class SidebarCtrl {
    constructor($state, Auth) {
      this.$state = $state;
      this.Auth = Auth;
      this.isAdmin = Auth.isAdmin;
      this.isOrganizationAdmin = Auth.isOrganizationAdmin;
      this.isAdmin = Auth.isAdmin;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.routeMap = {dashboard: ['main'], admin: ['admin'], manage: ['turbine', 'blade', 'finding', 'site'], quotes: ['wopackage'], printFindings: ['printFindings'], repairs: ['repairs'], print: ['report-config'], activity: ['activity-log'], clients: ['clients']};
      Auth.getCurrentUser((user)=> {
        if (user && user.organization) {
          this.user = user;
        } else {
          $state.go('login');
        }
      });
    }

    logout() {
      this.Auth.logout();
      this.$state.go('login');
    }

    isActive(route) {
      if (route) {
        return this.routeMap[route].indexOf(this.$state.current.name) > -1;
      }
      return false;
    }
  }

  angular.module('windmanagerApp')
    .controller('SidebarCtrl', SidebarCtrl);
})();
